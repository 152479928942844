.disciplines {
	margin-top: ac(100px, 60px);

	&__wrapper {
		padding-top: ac(84px, 24px);
	}

	&__card {
		padding: 29px 30px;
	}

	&__name {
		font-size: ac(40px, 26px);
		line-height: ac(50px, 34px);
	}

	&__item {
		font-size: ac(20px, 16px);
		line-height: ac(22px, 20px);
		letter-spacing: 0.4px;
		&:before {
			content: '';
			width: 12px;
			height: 12px;
			margin-right: 9px;
			background-color: var(--electric-red);
			border-radius: 100%;
		}
	}

	&__slider {
		.swiper-slide {
			&-active {
				.disciplines__img {
					img {
						filter: grayscale(1);
					}
				}
				.disciplines__mask {
					&::before {
						opacity: 0;
					}
					&::after {
						opacity: 1;
					}
				}
			}
		}
	}

	&__img {
		img {
			transition: 0.3s ease;
		}
	}

	&__mask {
		background: rgba(5, 21, 45, 0.3);

		&::before,
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			transition: 0.3s ease;
			/* z-index: -1; */
		}

		&::before {
			background: linear-gradient(
				180deg,
				rgba(36, 54, 91, 0) 0%,
				rgba(36, 54, 91, 0.3) 40.61%,
				#24365b 100%
			);
		}
		&::after {
			opacity: 0;
			background: linear-gradient(
				180deg,
				rgba(224, 25, 91, 0.3) 0%,
				#24365b 100%
			);
		}
	}
}
