.offers {
	margin-top: ac(56px, 30px);
	&__wrapper {
		padding-top: ac(100px, 30px);
	}
	&__accordion {
		&:not(:first-child) {
			margin-top: ac(65px, 20px);
		}
		padding-bottom: ac(61px, 20px);

		&:not(.is-active) {
			&:hover {
				.offers__head {
					cursor: pointer;
				}
				.offers__title {
					color: var(--electric-red);
				}
			}
		}

		&:not(.is-active) {
			.offers__title {
				font-size: ac(50px, 34px) !important;
				line-height: ac(50px, 40px) !important;
			}
		}

		&.is-active {
			.offers__plus {
				transform: rotate(90deg);
				span:first-child {
					width: 0;
				}
			}
		}
	}

	&__title {
		transition: 0.3s ease;

		@media (max-width: 768px) {
			font-size: ac(50px, 34px) !important;
			line-height: ac(50px, 40px) !important;
		}
	}
	&__content {
		padding-top: 30px;
		padding-bottom: 34px;
	}

	&__plus {
		&,
		span {
			transition: 0.3s ease;
		}
	}
}
