.map-section {
	margin-top: ac(156px, 56px);


	.cont-map {
		padding: 0;
		border-top: none;
	}

	&__content-wrap {
		padding-top: ac(100px, 56px);
		padding-bottom: 20px;
	}

}

.map-section__title {
	margin-bottom: ac(30px, 24px);
}

.map-section__description {
	max-width: 925px;
	margin: 0 auto;
}
.map-wrap{
	position: relative;
	transform: translate(0);
	.map-overlay{
		position: absolute;
		left: 54%;
		top: 50.6%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: auto;
		object-fit: contain;
		max-width: revert;
		opacity: 0.34;
		z-index: 0;

	}
	svg{
		position: relative;
		z-index: 1;
		fill: transparent!important;
		stroke: transparent!important;
	}
}

#map {
	background: var(--white);
}

.map-popover {
	position: absolute;
	z-index: 1;

	width: 121px;
	height: 70px;

	padding: 10px 16px 26px;

	background: url(../../assets/images/map/popover-bg.svg);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center bottom;

	opacity: 0;
	visibility: hidden;

	transition: all ease 400ms;
}

.map-popover.active {
	opacity: 1;
	visibility: visible;
}
#map .country {
	opacity: 0.34;
}

.marker {
	filter: none !important;
	transition: all ease 250ms;
}

.marker.active {
	filter: url(#markerShadow) !important;
	stroke: #fff !important;
}

.marker {
	cursor: pointer;
}

@media (max-width: 992px) {
	.cont-map{
		width: 100%;
		overflow: auto;
		margin-top: 50px;
		height: fit-content;
		.map-wrap{
			width: 1024px;

		}
	}
}