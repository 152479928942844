.testimonials {
	padding-bottom: ac(130px, 70px);
	&__slider-box {
		margin-top: ac(-224px, -80px);
	}
	&__slider {
		margin-top: ac(52px, 20px);
	}
	&__card {
		padding: ac(40px, 20px) ac(30px, 15px) ac(36px, 20px);
		background-color: var(--white);
		box-shadow: 0px 4px 20px rgba(5, 21, 45, 0.05);
	}

	&__logo {
		margin-bottom: ac(30px, 15px);
	}
	&__scroll {
		margin-bottom: ac(45px, 20px);
	}

	&__bottom {
		margin-top: ac(130px, 70px);
		gap: ac(106px, 20px);
	}

	&__item {
		&:not(:last-child) {
			margin-bottom: ac(8px, 5px);
		}
	}

	&__btn {
		margin-top: ac(40px, 20px);

		&:hover {
			background-color: var(--electric-red);
		}
	}

	@mixin md {
		&__list {
			max-width: ac(424px, 350px);
		}
	}
}
