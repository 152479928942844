.btn {
	display: inline-flex;
	padding: 12px 15px;

	font-size: 18px;
	line-height: 22px;
	font-weight: 700;
	font-family: var(--font-sec);

	border-radius: 10px;
	border: 1px solid var(--midnight);
	background: var(--white);
	transition: 0.3s ease;

	&:hover {
		background-color: var(--midnight);
		color: var(--white);
	}

	&.btn-pink {
		background-color: var(--electric-red);
		color: var(--white);
		border-color: var(--electric-red);

		&:hover {
			background-color: var(--midnight);
			border-color: var(--midnight);
		}
	}
}

.slider-btn {
	flex-shrink: 0;

	width: 23px;
	height: 16px;

	cursor: pointer;
	transition: 0.3s ease;
	user-select: none;
	z-index: 2;

	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;

	&.prev {
		margin-left: auto;

		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='16' fill='none'%3E%3Cpath fill='%2305152D' d='M.293 7.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L2.414 8l5.657-5.657A1 1 0 0 0 6.657.93L.293 7.293ZM23 7H1v2h22V7Z' /%3E%3C/svg%3E");
	}

	&.next {
		margin-left: 20px;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='16' fill='none'%3E%3Cpath fill='%2305152D' d='M22.707 8.707a1 1 0 0 0 0-1.414L16.343.929a1 1 0 1 0-1.414 1.414L20.586 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h22V7H0v2Z'/%3E%3C/svg%3E");
	}

	&--red {
		&.slider-btn.prev {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='16' fill='none'%3E%3Cpath fill='%23e0195b' d='M.293 7.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L2.414 8l5.657-5.657A1 1 0 0 0 6.657.93L.293 7.293ZM23 7H1v2h22V7Z' /%3E%3C/svg%3E");

			&:hover {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='16' fill='none'%3E%3Cpath fill='%2305152d' d='M.293 7.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L2.414 8l5.657-5.657A1 1 0 0 0 6.657.93L.293 7.293ZM23 7H1v2h22V7Z' /%3E%3C/svg%3E");
			}
		}

		&.slider-btn.next {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='16' fill='none'%3E%3Cpath fill='%23e0195b' d='M22.707 8.707a1 1 0 0 0 0-1.414L16.343.929a1 1 0 1 0-1.414 1.414L20.586 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h22V7H0v2Z'/%3E%3C/svg%3E");

			&:hover {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='16' fill='none'%3E%3Cpath fill='%2305152d' d='M22.707 8.707a1 1 0 0 0 0-1.414L16.343.929a1 1 0 1 0-1.414 1.414L20.586 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h22V7H0v2Z'/%3E%3C/svg%3E");
			}
		}
	}

	&--wh {
		&.slider-btn.prev {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='16' fill='none'%3E%3Cpath fill='%23fff' d='M.293 7.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L2.414 8l5.657-5.657A1 1 0 0 0 6.657.93L.293 7.293ZM23 7H1v2h22V7Z' /%3E%3C/svg%3E");
		}

		&.slider-btn.next {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='16' fill='none'%3E%3Cpath fill='%23fff' d='M22.707 8.707a1 1 0 0 0 0-1.414L16.343.929a1 1 0 1 0-1.414 1.414L20.586 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h22V7H0v2Z'/%3E%3C/svg%3E");
		}
	}

	&.swiper-button-lock {
		display: none !important;
	}

	&.swiper-button-disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	&:hover {
		&.prev {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='16' fill='none'%3E%3Cpath fill='%23e0195b' d='M.293 7.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L2.414 8l5.657-5.657A1 1 0 0 0 6.657.93L.293 7.293ZM23 7H1v2h22V7Z' /%3E%3C/svg%3E");
		}

		&.next {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='16' fill='none'%3E%3Cpath fill='%23e0195b' d='M22.707 8.707a1 1 0 0 0 0-1.414L16.343.929a1 1 0 1 0-1.414 1.414L20.586 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h22V7H0v2Z'/%3E%3C/svg%3E");
		}
	}
}

@mixin max-xs {
	.btn {
		padding: 12px 10px;
		font-size: 16px;
	}
}

.btn-link {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: ac(12px, 10px);
	cursor: pointer;

	i {
		font-size: ac(15px, 14px);
		color: #E0195B;
		transition: transform .3s ease;
	}

	.btn-link__text {
		font-family: var(--font-sec);
		font-size: ac(18px, 16px);
		line-height: 1.2;
		color: var(--white);
	}

	&.btn-link-dark {
		.btn-link__text {
			color: var(--denim);
		}
	}

	&:hover {
		i {
			transform: translateX(-50%);
		}
	}
}

.social-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: ac(48px, 40px);
	color: var(--electric-red);
	cursor: pointer;
	
	&:hover {
		color: var(--denim);
	}
}
