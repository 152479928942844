.fp-watermark {
	display: none;
}

html {
	font-size: ac(18px, 16px);
	line-height: ac(26px, 24px);
	font-weight: 300;
	font-family: var(--font-main);

	scroll-padding-top: 70px;
	scroll-behavior: smooth;
	scrollbar-width: thin;
	scrollbar-color: var(--midnight) rgba(30, 30, 30, 0.2);

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		background: rgba(30, 30, 30, 0.2);
		border-radius: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: var(--midnight);
		border-radius: 0;
	}
}

body {
	min-width: 320px;
	background-color: var(--white);
	color: var(--midnight);
}

#site {
	position: relative;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	min-height: 100dvh;
	overflow: hidden;
}

.main {
	flex-grow: 1;
}

.cont {
	width: 100%;
	margin: 0 auto;
	max-width: 1282px;
	padding: 0 20px;
}

.js-accordion {
	&.is-active {
		.js-accordion__content {
			visibility: visible;
			max-height: 4000px;
			opacity: 1;
		}
	}

	&__content {
		visibility: hidden;
		max-height: 0;
		overflow: hidden;
		opacity: 0;
		transition: 0.35s;
			}
}

[data-simplebar] {
	width: 100%;

	.simplebar-track.simplebar-vertical {
		width: 4px;
	}

	.simplebar-scrollbar::before {
		border-radius: 0;
		opacity: 1 !important;
		top: 0 !important;
		left: 0;
		bottom: 0 !important;
		right: 0;
		background-color: var(--white);
	}

	.simplebar-track {
		background-color: rgba(255, 255, 255, 0.2);
		border-radius: 0;
	}

	&.simple-dark {
		.simplebar-scrollbar::before {
			background-color: var(--midnight);
		}

		.simplebar-track {
			background-color: rgba(0, 0, 0, 0.2);
		}
	}

	&.scroll-center {
		width: calc(100% + 12px);
		padding: 0 6px;
		max-height: calc(ac(26px, 24px) * 9);
	}
	&.scroll {
		width: 100%;
		padding-right: ac(10px, 8px);
		max-height: calc(ac(26px, 24px) * 9);
	}
}

.swiper {
	width: 100%;
	overflow: visible;
}

.swiper-wrapper {
	left: 0 !important;
}

img,
video,
svg {
	width: 100%;
	height: 100%;
}

img,
video {
	object-fit: cover;
}

svg {
	object-fit: contain;
}

#bold-credits {
	width: 120px;
	transition: ease-in-out 0.25s;

	&:hover {
		transform: scale(1.1);
	}
}

#site.cookie-terms-page {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;

	.main {
		flex-grow: 1;
		padding: 0;
	}
}

.cookie-terms-section {
	padding: ac(250px, 140px) 0 ac(150px, 50px);

	h1 {
		color: var(--midnight);

		&:not(:last-child) {
			margin-bottom: ac(40px, 30px);
		}
	}

	h2 {
		font-size: ac(50px, 34px);
		line-height: ac(50px, 40px);

		&:not(:last-child) {
			margin-bottom: ac(34px, 24px);
		}
	}

	h3 {
		font-size: ac(40px, 26px);
		line-height: ac(48px, 30px);
		font-family: var(--font-main);
		font-weight: 700;

		&:not(:last-child) {
			margin-bottom: ac(34px, 24px);
		}
	}

	h4 {
		font-size: ac(30px, 22px);
		line-height: 1.2;
		font-family: var(--font-main);
		font-weight: 700;

		&:not(:last-child) {
			margin-bottom: ac(30px, 24px);
		}
	}

	p {
		padding: 0;
		margin-bottom: 10px;

		+ h1 {
			padding-top: 20px;
		}

		+ h2 {
			padding-top: 20px;
		}

		+ h3 {
			padding-top: 10px;
		}

		+ h4 {
			padding-top: 10px;
		}
	}

	ul,
	ol {
		padding-left: 20px;
		li {
			p {
				margin-bottom: 0;
			}
		}

		margin-bottom: 10px;
	}

	ul {
		list-style: circle;
	}

	ol {
		list-style: decimal;
	}

	a {
		display: inline;
		text-decoration: underline;
		transition: 0.2s;
		color: var(--electric-red);

		&:hover {
			opacity: 0.7;
		}
	}
}

.single-page-404 {
	min-height: 100vh;
	padding: 20px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h1,
	p {
		margin-bottom: 15px;
		padding: 0;
	}

	a {
		display: inline-block;
		margin-top: 20px;
	}
}

.section-container {
	overflow: hidden;
	width: 100%;
}
