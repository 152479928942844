.sectors {
	margin-top: ac(52px, 30px);

	&__wrapper {
		padding-top: ac(22px, 15px);
	}

	&__thumb {
		margin-top: ac(37px, 15px);

		.swiper-slide {
			cursor: pointer;
			opacity: 0.4;
			transition: opacity 0.3s ease;

			&-thumb-active {
				opacity: 1;
				pointer-events: none;
			}

			&:hover {
				opacity: 1;
			}
		}
	}
	&__slider {
		.swiper-slide {
			opacity: 0 !important;

			&-active {
				opacity: 1 !important;
			}
		}
	}

	&__tab {
		font-size: ac(20px, 16px);
		line-height: ac(30px, 26px);

		span {
			line-height: ac(22px, 18px);
			font-size: ac(22px, 18px);
		}
	}

	&__scroll {
		&.scroll {
			max-height: calc(ac(26px, 24px) * 7 + ac(10px, 5px));
		}
	}

	&__descr {
		& > *:not(:last-child) {
			margin-bottom: ac(10px, 5px);
		}
	}

	@mixin md {
		&__col {
			max-width: ac(606px, 350px);
		}
	}
}
