@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@400;700&family=Montserrat:wght@300;400;500&display=swap');

:root {
	--white: #ffffff;
	--midnight: #05152d;
	--denim: #24365b;
	--electric-red: rgb(224, 25, 91);

	--font-main: 'Montserrat', sans-serif;
	--font-sec: 'Gabarito', sans-serif;

	--vh: calc(100vh / 100);
}
