.popup-case {
	max-width: 1044px;
	max-height: 85dvh;
	padding: 0;

	&[data-simplebar] {
		overflow-x: hidden;
		.simplebar-track {
			background-color: rgba(224, 25, 91, 0.2);
		}

		.simplebar-scrollbar:before {
			background-color: var(--electric-red);
		}
	}

	.simplebar-horizontal {
		display: none !important;
	}

	.popup__btn-close {
		display: none;
	}

	&__close {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: ac(30px, 15px);
		right: ac(30px, 15px);
		width: 48px;
		height: 48px;
		border: 1px solid var(--white);
		background-color: var(--denim);

		border-radius: 10px;
		transition: 0.3s ease;
		cursor: pointer;
		z-index: 5;

		&::after {
			content: '';
			width: 16px;
			height: 16px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='m15.097 2.369-2.01-2.01-5.36 5.36L2.37.359.359 2.37l5.359 5.36-5.359 5.358 2.01 2.01 5.359-5.359 5.359 5.36 2.01-2.01-5.36-5.36 5.36-5.36Z' clip-rule='evenodd'/%3E%3C/svg%3E");
			cursor: pointer;
			transition: 0.3s ease;
		}

		&:hover {
			background-color: var(--white);
			&::after {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath fill='%2324365B' fill-rule='evenodd' d='m15.097 2.369-2.01-2.01-5.36 5.36L2.37.359.359 2.37l5.359 5.36-5.359 5.358 2.01 2.01 5.359-5.359 5.359 5.36 2.01-2.01-5.36-5.36 5.36-5.36Z' clip-rule='evenodd'/%3E%3C/svg%3E");
				cursor: pointer;
				cursor: pointer;
			}
		}
	}

	&__head {
		padding: ac(50px, 30px) ac(60px, 15px) ac(40px, 30px);
	}

	&__title {
		max-width: calc(100% - ac(200px, 55px, 320));
		font-size: ac(50px, 28px, 320);
		line-height: ac(56px, 32px, 320);
	}

	&__row {
		gap: ac(20px, 15px);
		margin-top: ac(24px, 20px);
		border: 1px solid var(--electric-red);
		align-items: stretch;
	}

	&__card-box {
		gap: ac(20px, 10px);
		align-items: stretch;

		@media (max-width: 768px) {
		  flex-direction: column;
			align-items: stretch;
			justify-content: flex-start;
		}
	}

	&__card {
		margin-top: 10px;
		margin-bottom: 10px;
		min-width: ac(190px, 120px);
		justify-content: center;

		&:not(:first-child) {
			padding-left: ac(20px, 10px);
			border-left: 1px solid var(--electric-red);
		}

		@media (max-width: 768px) {
			padding-left: 16px;
			padding-right: 16px;

			&:not(:first-child) {
				padding-left: 16px;
				border-top: 1px solid var(--electric-red);
				border-left: none;
				padding-top: 20px;

			}
		}
	}

	&__count-box {
		margin-top: ac(20px, 15px);
		display: inline-flex;
		align-items: center;
		flex-wrap: wrap;
		font-family: var(--font-sec);
		font-size: ac(30px, 22px);
		line-height: 1em;
		font-weight: 700;
		grid-row-gap: 6px;

		.space {
			&:before {

			}
		}
	}

	&__count {
		margin-right: ac(8px, 6px);
	}

	&__logo {
		min-height: ac(152px, 100px, 320);
		padding: ac(20px, 10px) ac(30px, 16px);
		max-width: 200px;

		@media (max-width: 551px) {
			max-width: 100%;
			width: 100%;
		}
	}

	&__subtitle {
		margin-top: ac(24px, 20px);
		font-size: ac(30px, 18px);
		line-height: ac(36px, 24px);
	}

	&__accordion-box {
		border: 1px solid rgba(36, 54, 91, 0.5);
		border-top: 0;
		background-color: #f5f5f5;
		padding: ac(38px, 20px) ac(60px, 15px) ac(60px, 15px);
	}

	&__accordion {
		transition: 0.3s ease;

		&.is-active {
			margin-bottom: -6px;
			.popup-case__btn {
				cursor: default;
			}
			.popup-case__plus {
				width: 34px;
				height: 34px;

				span:first-child {
					&::before,
					&::after {
						background-color: var(--denim);
					}

					&:before {
						height: 10px;
					}
				}

				span:last-child {
					&:before {
						height: 6px;
					}
				}
			}
		}
	}

	&__plus {
		width: 34px;
		height: 34px;

		span:first-child {
			width: 34px;
			height: 34px;

			&::before,
			&::after {
				content: '';
				position: absolute;
				background-color: var(--electric-red);
				transition: 0.3s linear;
			}

			&:before {
				width: 12px;
				height: 34px;
			}
			&:after {
				height: 12px;
				width: 34px;
			}
		}

		span:last-child {
			width: 28px;
			height: 28px;

			&::before,
			&::after {
				content: '';
				position: absolute;
				background-color: var(--white);
				transition: 0.3s linear;
			}

			&:before {
				width: 6px;
				height: 28px;
			}
			&:after {
				height: 6px;
				width: 28px;
			}
		}
	}

	&__text {
		padding-top: ac(22px, 15px);
	}

	&__btn {
		&:hover {
			.popup-case__plus {
				span:first-child {
					&::before,
					&::after {
						background-color: var(--denim);
					}
				}
			}
		}
	}

	@mixin md {
		&__logo {
			border-left: 1px solid var(--electric-red);
		}
	}
}
