.jobs {
	padding-top: ac(129px, 69px);

	&.home-page {
		margin-top: ac(102px, 60px);
		padding-bottom: ac(102px, 60px);

		&:before {
			content: none;
		}

		+ .radar {
			margin-top: 0;
			background: rgb(36 54 91);
		}
	}

	&--career {
		background-color: var(--white);

		&.jobs {
			&::before {
				content: '';
				position: absolute;
				left: 50%;
				top: 0;
				transform: translateX(-50%);
				width: 100vw;
				height: 400px;
				background-color: var(--denim);
			}
		}

		.jobs__card {
			border: 1px solid rgba(36, 54, 91, 0.5);
			box-shadow: 0px 4px 20px rgba(5, 21, 45, 0.05);
		}
	}

	&__card {
		padding: ac(30px, 24px) ac(30px, 24px) ac(22px, 28px);
		box-shadow: 0px 4px 20px 0px rgba(5, 21, 45, 0.05);
		cursor: pointer;
		transition: 0.3s ease;

		&:hover {
			border-color: var(--electric-red);

			.jobs__name {
				color: var(--electric-red);
			}
			.jobs__icon {
				transform: translateX(10px);
			}
		}
	}

	&__name {
		margin-bottom: ac(60px, 20px);

		font-family: var(--font-sec);
		font-size: ac(40px, 24px);
		line-height: 40px;
		font-weight: 400;
		transition: 0.3s ease;

		max-height: calc(40px * 4);
		@mixin max-line-length 4;
		padding-bottom: 2px !important;
	}

	&__location {
		font-size: ac(22px, 18px);
		line-height: 22px;
		font-weight: 500;
	}

	&__info {
		margin-top: ac(10px, 13px);
		line-height: 26px;
	}

	&__icon {
		transition: 0.3s ease;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='16' fill='none'%3E%3Cpath fill='%23E0195B' d='M22.707 8.707a1 1 0 0 0 0-1.414L16.343.929a1 1 0 1 0-1.414 1.414L20.586 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h22V7H0v2Z'/%3E%3C/svg%3E");
	}

	@mixin md {
		&::before {
			content: '';
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
			width: 100vw;
			height: 116px;
			background-color: var(--white);
		}
	}

	@mixin lg {
		&--career {
			&.jobs {
				&::before {
					height: 604px;
				}
			}
		}
	}

	@mixin max-md {
		padding-bottom: 70px;

		&__btn {
			order: 1;
			margin: 28px auto 0;
		}
	}
}
