h1,
h2,
.h2 {
	font-family: var(--font-sec);
	font-weight: 700;
}

h1,
.h1 {
	font-size: ac(100px, 40px);
	line-height: ac(100px, 50px);
	color: var(--white);
}

h2,
.h2 {
	font-size: ac(80px, 40px);
	line-height: 1em;

	&.lg {
		font-weight: 400;
		font-size: ac(90px, 30px, 320);
		line-height: ac(90px, 38px, 320);
	}
	&.sm {
		font-size: ac(50px, 34px);
		line-height: ac(50px, 40px);
	}
	&.xs {
		font-size: ac(40px, 26px);
		line-height: ac(48px, 30px);
		font-family: var(--font-main);
		font-weight: 400;
	}
}

.name {
	font-size: ac(40px, 30px);
	line-height: 1em;
}

.name-md {
	font-size: ac(30px, 24px);
	line-height: 1em;
	font-weight: 700;
	font-family: var(--font-sec);
}
.name-sm {
	font-size: ac(22px, 18px);
	line-height: 22px;
	font-weight: 700;
	font-family: var(--font-sec);
}

.sub {
	font-size: ac(22px, 18px);
	line-height: ac(28px, 24px);
	font-weight: 500;
}

@mixin max-xs {
	h1,
	.h1 {
		font-size: 32px;
		line-height: 40px;
		color: var(--white);
	}

	h2,
	.h2 {
		font-size: 30px;

		&.sm {
			font-size: 24px;
			line-height: 32px;
		}
	}

	.name {
		font-size: 26px;
	}
}

.content-element-link {
	a {
		display: inline-flex;
		color: var(--electric-red);
		cursor: pointer;
		text-decoration: underline;

		&:hover {
			color: var(--midnight);
		}
	}
}

.content-element {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
	}

	a {
		display: inline-flex;
		color: var(--electric-red);
		cursor: pointer;
		text-decoration: underline;

		&:hover {
			color: var(--midnight);
		}
	}

	ol {
		list-style-type: none;
		counter-reset: num;
		display: grid;
		li {
			position: relative;
			padding-left: ac(38px, 24px);
			&:before {
				position: absolute;
				color: var(--electric-red);
				top: 0;
				left: ac(5px, 0px);
				font: inherit;
				content: counter(num) '.';
				counter-increment: num;
			}

			&:not(:last-child) {
				margin-bottom: ac(12px, 8px);
			}
		}

		+ p {
			padding-top: ac(8px, 0px);
		}

		&:not(:last-child) {
			margin-bottom: ac(10px, 8px);
		}
	}

	ul {
		display: grid;

		li {
			position: relative;
			display: block;
			padding-left: ac(38px, 24px);

			&:before {
				font-family: 'icomoon' !important;
				speak: never;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;

				/* Better Font Rendering =========== */
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				position: absolute;
				content: "\e900";
				color: var(--electric-red);
				top: ac(0px, 3px);
				left: 0;
				font-size: ac(30px, 20px);
			}

			&:not(:last-child) {
				margin-bottom: ac(12px, 8px);
			}
		}

		+ p {
			padding-top: ac(8px, 0px);
		}

		&:not(:last-child) {
			margin-bottom: ac(10px, 8px);
		}
	}

	li,
	p {
		/*Not Design*/
		&:not(:last-child) {
			margin-bottom: ac(20px, 16px);
		}

		strong {
			font-weight: 500 !important;
		}
	}
}

