.first-detail-section {
  margin-top: ac(122px, 100px);
  background: var(--denim);
  padding-top: ac(95px, 40px);
  padding-bottom: ac(95px, 40px);

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__condent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 57.33%;
  }

  &__subtitle {
    font-family: var(--font-sec);
    font-size: ac(18px, 16px);
    line-height: 1.2;
    color: var(--white);
    opacity: .8;
    
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__title {
    font-family: var(--font-sec);
    font-weight: 700;
    font-size: ac(50px, 34px);
    line-height: 1.2;
    color: var(--white);
    padding-left: ac(16px, 8px);
    padding-right: ac(16px, 8px);
    position: relative;
    z-index: 10;
    overflow: hidden;

    &:before {
      position: absolute;
      top: -5px;
      bottom: -5px;
      left: 0;
      width: ac(18px, 10px);
      content: "";
      background: var(--electric-red);
      z-index: -2;
    }

    span.word {
      position: relative;
      z-index: 1;

      &:after {
        position: absolute;
        inset: 0 -7px;
        background: var(--electric-red);
        content: '';
        z-index: -1;
        transition: opacity 0.3s ease;
      }

      &:first-child {
        &:after {
          left: ac(-16px, -8px);
        }
      }

      &:last-child {
        &:after {
          right: ac(-16px, -8px);
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(48px, 30px);
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: ac(32px, 20px);

    &.with-mob {
      @media (min-width: 769px) {
        display: none;
      }
    }

    &:not(.with-mob) {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  &__list {
    width: 40.10%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    color: var(--white);
    border: 1px solid var(--electric-red);
    padding-left: ac(32px, 20px);
    padding-right: ac(32px, 20px);
    padding-top: ac(28px, 24px);
    padding-bottom: ac(28px, 24px);

    li {
      font-family: var(--font-sec);
      font-size: ac(18px, 16px);
      line-height: 1.2;
      font-weight: 400;

      &:not(:last-child) {
        padding-bottom: ac(14px, 10px);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }

      &:not(:first-child) {
        padding-top: ac(14px, 10px);
      }
    }
  }

  @media (max-width: 768px) {
    .first-detail-section {
      &__wrap {
        flex-direction: column;
        grid-row-gap: 30px;
        align-items: flex-start;
      }

      &__content {
        width: 100%;
      }

      &__list {
        width: 100%;
        max-width: 450px;
      }
    }
  }
}