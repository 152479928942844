.popup-apply {
	max-width: 1044px;
	max-height: 85dvh;
	padding: ac(60px, 30px) ac(60px, 15px);

	&[data-simplebar] {
		.simplebar-track {
			background-color: rgba(224, 25, 91, 0.2);
		}

		.simplebar-scrollbar:before {
			background-color: var(--electric-red);
		}
	}

	.simplebar-horizontal {
		display: none !important;
	}

	.popup__btn-close {
		display: none;
	}

	&__close {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: ac(30px, 15px);
		right: ac(30px, 15px);
		width: 48px;
		height: 48px;
		border: 1px solid var(--white);
		background-color: var(--denim);

		border-radius: 10px;
		transition: 0.3s ease;
		cursor: pointer;
		z-index: 5;

		&::after {
			content: '';
			width: 16px;
			height: 16px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='m15.097 2.369-2.01-2.01-5.36 5.36L2.37.359.359 2.37l5.359 5.36-5.359 5.358 2.01 2.01 5.359-5.359 5.359 5.36 2.01-2.01-5.36-5.36 5.36-5.36Z' clip-rule='evenodd'/%3E%3C/svg%3E");
			cursor: pointer;
			transition: 0.3s ease;
		}

		&:hover {
			background-color: var(--white);
			&::after {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3E%3Cpath fill='%2324365B' fill-rule='evenodd' d='m15.097 2.369-2.01-2.01-5.36 5.36L2.37.359.359 2.37l5.359 5.36-5.359 5.358 2.01 2.01 5.359-5.359 5.359 5.36 2.01-2.01-5.36-5.36 5.36-5.36Z' clip-rule='evenodd'/%3E%3C/svg%3E");
				cursor: pointer;
				cursor: pointer;
			}
		}
	}

	&__copy {
		a {
			display: inline-block;
			text-decoration: underline;
			transition: 0.3s ease;
			&:hover {
				color: var(--electric-red);
			}
		}
	}
}
