.cases {
	margin-top: ac(113px, 60px);
	padding-top: ac(130px, 60px);

	&__card {
		padding: 29px 30px 22px;
		background-color: var(--white);
		border: 1px solid var(--white);
		cursor: pointer;
		transition: 0.3s ease;

		&:hover {
			border-color: var(--electric-red);
			.cases__icon {
				transform: translateX(10px);
			}
		}
	}

	&__icon {
		transition: 0.3s ease;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='16' fill='none'%3E%3Cpath fill='%23E0195B' d='M22.707 8.707a1 1 0 0 0 0-1.414L16.343.929a1 1 0 1 0-1.414 1.414L20.586 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h22V7H0v2Z'/%3E%3C/svg%3E");
	}

	&__descr {
		max-height: calc(ac(40px, 30px) * 5);
		@mixin max-line-length 5;
	}
}
