.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100%;
	z-index: 300;
	transition: 0.3s ease;

	&__socials {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 12px;

		&:not(:last-child) {
			margin-bottom: 12px;
		}

		.help__social {
			&:hover {
				svg {
					path {
						fill: var(--white) !important;
					}
				}
			}
		}
	}

	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: var(--midnight);
		opacity: 0;
		transition: 0.3s ease;
	}

	&--sec {
		&::before {
			background-color: var(--white);
		}
	}

	&.sticky {
		backdrop-filter: blur(10px);

		&::before {
			opacity: 0.5;
		}
		.header {
			&__wrapper {
				padding: 0;
			}
		}
	}

	&__wrapper {
		transition: 0.3s ease;
	}

	&__logo-box {
		padding: 20px ac(85px, 20px) 20px 0;
		z-index: 3;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0%;
			width: calc(100% + 100vw);
			height: 100%;
			background-color: var(--white);
			z-index: -1;
		}
	}

	&__logo {
		width: 309px;
		height: 42px;

		path {
			transition: 0.3s ease;
		}

		&:hover {
			path {
				fill: var(--electric-red);
			}
		}
	}

	&__link {
		padding-bottom: 4px;
		position: relative;
		color: var(--white);
		font-family: var(--font-sec);
		font-weight: 700;
		transition: 0.3s ease;

		&:before {
			content: '';
			position: absolute;
			bottom: 0px;
			width: 0;
			height: 2px;
			background-color: var(--electric-red);
			transition: 0.3s ease;
		}

		&:hover {
			&::before {
				width: 100%;
			}
		}
	}

	@media (min-width: 1024px) {
		&--sec {
			.header__link {
				color: var(--midnight);
			}
		}
		&__navbar-box {
			display: flex;
			flex-wrap: wrap;
			width: calc(100% - 400px);
		}

		&__simplebar {
			width: 100%;
			width: 100%;
			max-height: 22px;
			margin-left: auto;

			.simplebar-content-wrapper,
			.simplebar-mask {
				overflow: visible !important;
			}

			.simplebar-track {
				display: none !important;
			}

			.simplebar-content {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}

		&__link {
			flex-shrink: 0;
			font-size: 18px;
			line-height: 22px;

			&:not(:nth-last-child(2)) {
				margin-right: ac(51px, 20px, 1024, 1440);
			}
		}
	}

	@media (max-width: 1023px) {
		&--sec:not(.active) {
			.header__burger {
				span {
					background-color: var(--midnight);
				}
			}
		}
		&.active {
			.header__layout {
				visibility: visible;
				opacity: 1;
				background-color: rgba(255, 255, 255, 0.5);
				transition: 0.3s linear;
			}

			.header__navbar-box {
				right: 0;
				opacity: 1;
				visibility: visible;
			}

			.header__simplebar {
				transform: translateX(0);
				opacity: 1;
			}

			.header__decor {
				opacity: 1;
				transform: translate(calc(-50% - 135px), calc(-50% - 180px));
				transition: 0.5s 0.3s ease-in-out;
			}

			.header__burger {
				span {
					width: 26px;

					&:nth-child(1) {
						transform: translate(0) rotate(45deg);
					}

					&:nth-child(2) {
						width: 0;
					}

					&:nth-child(3) {
						transform: translate(0) rotate(-45deg);
					}
				}
			}
		}

		&__logo {
			width: 256px;
			height: 35px;
		}

		&__burger {
			span {
				transition: 0.3s ease-in-out;

				&:nth-child(1) {
					transform: translateY(-9px);
				}

				&:nth-child(3) {
					transform: translateY(9px);
				}
			}
		}

		&__navbar-box {
			position: absolute;
			overflow: hidden;
			right: -100%;
			top: 0;
			display: flex;
			align-items: flex-start;
			flex-direction: column;
			max-width: 450px;
			width: 100%;
			height: calc((var(--vh, 1vh) * 100) - 0px);
			padding: 18vh 20px 19px;
			background-color: var(--midnight);

			transition: 0.3s ease;
			opacity: 0;
			visibility: hidden;
			transition: 0.3s ease;
			z-index: 2;
		}

		&__decor {
			left: 50%;
			top: 50%;
			transform: translate(-100%, 50%);
			opacity: 0;
			width: 853px;
			height: 454px;
			transform: 0.5s ease-in-out;
		}

		&__simplebar {
			max-height: calc((var(--vh, 1vh) * 100) - 300px);
			width: 100%;
		}

		&__navbar {
			width: 100%;
			flex-direction: column;
			align-items: flex-start;
			padding-bottom: 2px;
		}

		&__link {
			font-size: 40px;
			line-height: 48px;
			margin: 1.35vh 0;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&--alt {
				&::after {
					content: '';
					position: absolute;
					right: -20px;
					top: 50%;
					transform: translate(100%, calc(-50% - 2px));
					width: 48px;
					height: 48px;
					background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'%3E%3Cpath fill='%23E0195B' d='M8.207 18.584c-9.703 12.943 6.024 11.546 23.573-1.165L26.013 12 47 12.043l-7.412 20.75-2.907-7.38C-6.427 50.675-2.82 23.425 8.191 18.599'/%3E%3C/svg%3E")
					center / contain no-repeat;
				}
				&::before {
					width: 100%;
				}
			}
		}

		&__soc {
			width: 24px;
			height: 24px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			transition: 0.3s ease;

			&--in {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23E0195B' d='M4.98 3A2.49 2.49 0 0 1 2.5 5.5 2.49 2.49 0 0 1 .02 3C.02 1.62 1.13.5 2.5.5A2.49 2.49 0 0 1 4.98 3ZM5 7.5H0v16h5v-16Zm7.982 0H8.014v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V23.5H24V13.369c0-7.88-8.922-7.593-11.018-3.714V7.5Z'/%3E%3C/svg%3E");

				&:hover {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23fff' d='M4.98 3A2.49 2.49 0 0 1 2.5 5.5 2.49 2.49 0 0 1 .02 3C.02 1.62 1.13.5 2.5.5A2.49 2.49 0 0 1 4.98 3ZM5 7.5H0v16h5v-16Zm7.982 0H8.014v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V23.5H24V13.369c0-7.88-8.922-7.593-11.018-3.714V7.5Z'/%3E%3C/svg%3E");
				}
			}
		}

		&__tel,
		&__text {
			color: var(--white);

			font-size: 16px;
			line-height: 24px;
			font-weight: 300;
		}

		&__tel {
			&:not(:first-of-type) {
				margin-top: 8px;
			}

			transition: 0.3s ease;

			&:hover {
				color: var(--electric-red);
			}
		}

		&__text {
			margin-top: 14px;
		}
	}

	@mixin max-md {
		&__navbar-box {
			max-width: unset;
		}
	}

	@mixin max-xs {
		&__logo {
			width: 200px;
		}

		&__link {
			font-size: 30px;
			line-height: 38px;
		}
	}
}
