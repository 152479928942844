.benefits {
	margin-top: ac(56px, 35px);

	&__wrapper {
		gap: ac(82px, 20px);
		padding: ac(104px, 46px) ac(27px, 0px) 0;
		border-top: 4px solid var(--denim);
	}
	&__card {
		/* padding: 0 ac(47px, 0px); */
	}
}
