.help {
	margin-top: ac(96px, 66px);

	&--who {
		margin-top: ac(126px, 70px);
	}

	&__wrapper {
		padding-top: ac(100px, 70px);
	}

	&__title {
		&.sm {
			font-size: ac(44px, 32px);
			line-height: ac(48px, 40px);

			@media (max-width: 1200px) {
				font-size: ac(40px, 32px);
				line-height: ac(48px, 40px);

			}
		}
	}

	&__socials {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: ac(16px, 14px);
		margin-left: ac(40px, 0px);

		@media (max-width: 374px) {
		  flex-direction: column;
			align-items: flex-start;
		}
	}

	&__socials-title {
		font-size: 18px;
		font-weight: 400;
		line-height: 26px;
		font-family: var(--font-sec);
		white-space: nowrap;
	}

	&__socials-wrap {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		grid-column-gap: 8px;
	}

	&__social {
		--size: ac(48px, 44px);
		width: var(--size);
		height: var(--size);
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			width: 100%;
			height: 100%;

			path {
				fill: var(--electric-red);
				transition: .3s;
			}
		}

		&:hover {
			svg{
				path {
					fill: var(--midnight);
				}
			}
		}
	}

	&__btn {
		position: relative;
		font-family: var(--font-sec);
		font-size: 18px;
		font-weight: 400;
		line-height: 26px;
		transition: 0.3s ease;
		white-space: nowrap;

		&::after {
			content: '';
			margin-left: 12px;
			width: 30px;
			height: 16px;
			background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='16' fill='none'%3E%3Cpath fill='%23E0195B' d='M4.7 4.389c-6.328 8.63 3.929 7.698 15.374-.776L16.313 0 30 .029l-4.834 13.833-1.896-4.92C-4.844 25.783-2.492 7.617 4.69 4.4'/%3E%3C/svg%3E")
				center / contain no-repeat;
		}

		&::before {
			content: '';
			position: absolute;
			bottom: 0px;
			width: calc(100% - 42px);
			height: 2px;
			background-color: var(--electric-red);
			transition: 0.3s ease;
		}

		&:hover {
			color: var(--electric-red);
		}

		&--tel {
			&::before {
				width: calc(100% - 36px);
			}
			&::after {
				margin-left: 12px;
				width: 24px;
				height: 24px;
				background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23E0195B' d='M14.275 3.556a.75.75 0 0 1 .92-.53 8.083 8.083 0 0 1 5.78 5.78.751.751 0 0 1-1.182.79.75.75 0 0 1-.268-.402 6.595 6.595 0 0 0-4.718-4.72.75.75 0 0 1-.532-.918Zm-.218 3.919c1.293.345 2.123 1.176 2.468 2.47a.75.75 0 0 0 1.45-.389c-.48-1.796-1.735-3.05-3.53-3.53a.75.75 0 1 0-.388 1.449Zm6.79 7.38-4.416-1.978-.013-.006a1.5 1.5 0 0 0-1.492.184L12.644 15c-1.446-.702-2.938-2.183-3.64-3.61l1.948-2.317a1.5 1.5 0 0 0 .177-1.484v-.011L9.144 3.154a1.5 1.5 0 0 0-1.558-.893A5.274 5.274 0 0 0 3 7.5C3 14.944 9.056 21 16.5 21a5.275 5.275 0 0 0 5.239-4.586 1.5 1.5 0 0 0-.892-1.558Z'/%3E%3C/svg%3E")
					center / contain no-repeat;
			}
		}
		&--mail {
			&::before {
				width: calc(100% - 38px);
			}
			&::after {
				margin-left: 14px;
				width: 24px;
				height: 24px;
				background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23E0195B' d='M21 4.5H3a.75.75 0 0 0-.75.75V18a1.5 1.5 0 0 0 1.5 1.5h16.5a1.5 1.5 0 0 0 1.5-1.5V5.25A.75.75 0 0 0 21 4.5ZM9.254 12 3.75 17.045V6.955L9.254 12Zm1.11 1.017 1.125 1.036a.75.75 0 0 0 1.014 0l1.125-1.036L19.066 18H4.928l5.436-4.983ZM14.746 12l5.504-5.046v10.092L14.746 12Z'/%3E%3C/svg%3E")
					center / contain no-repeat;
			}
		}
	}

	@media (max-width: 640px) {
		&__title {
			max-width: 200px;
		}
	}

	@media (max-width: 451px) {
		&__title {
			max-width: 100%;
			width: 100%;
		}
	}
}
