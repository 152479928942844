.radar {
	margin-top: ac(102px, 70px);

	&.radar-bg {
		position: relative;
		z-index: 5;

		&:after {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: ac(93px, 50px);
			background: var(--midnight);
			content: "";
			z-index: -1;
			
		}
	}

	&--who {
		margin-top: ac(130px, 70px);
	}

	&__wrapper {
		padding: ac(210px, 70px) 0;
	}

	&__subtitle {
		margin-top: ac(40px, 22px);
		max-height: calc(ac(26px, 24px) * 5);
		@mixin max-line-length 5;
	}

	&__btn {
		transition: 0.3s ease !important;
	}

	@mixin md {
		&__wrapper {
			max-width: ac(606px, 350px);
		}

		&__img {
			aspect-ratio: 0.85 / 1;
			transform: translateX(calc(-50% + ac(474px, 220px)));
		}

		&__decor {
			width: ac(1575px, 1000px);
			transform: translateX(calc(-50% - ac(371px, 300px)));
			height: calc(100% - 60px);
		}

		&--who {
			.radar__img {
				aspect-ratio: 0.7/1;
				transform: translateX(calc(-50% + ac(406px, 238px, 768, 1366)));
			}
		}
	}

	@mixin max-md {
		&__wrapper {
			padding-top: 0;
		}

		&__img {
			aspect-ratio: 4 / 3;
			width: calc(100% + 40px);
			margin-left: -20px;
			margin-bottom: 70px;

			img {
				object-position: 0% 6%;
			}
		}

		&__decor {
			width: 504px;
			height: 482px;
			top: 50%;
			right: -1px;
			transform: translateY(calc(-50% + 34px));
		}

		&--who {
			.radar__img {
				img {
					object-position: center;
				}
			}
		}
	}

	@mixin xxl {
		&__img {
			aspect-ratio: 0.85 / 1;
			width: 50vw;
			transform: translateX(calc(-50% + ac(700px, 360px, 1440, 2560)));

			img {
				object-position: top;
			}
		}
	}
}
