.approach {
	margin-top: ac(145px, 69px);

	&__wrapper {
		gap: 30px;
	}

	&__scroll {
		max-height: calc(ac(26px, 24px) * 15 + 10px);

		.simplebar-track {
			right: -10px;
		}
	}

	&__descr {
		& > *:not(:first-child) {
			margin-top: 10px;
		}
	}

	&__btn {
		margin-top: ac(50px, 28px);
	}

	&__slider-box {
		gap: ac(25px, 34px);
	}

	&__card {
		min-height: ac(462px, 392px);
		padding: ac(30px, 20px) ac(30px, 20px) ac(34px, 20px);
		border: 1px solid transparent;
		transition: 0.3s ease;

		&:hover {
			border-color: var(--electric-red);

			.approach__icon {
				background-color: var(--electric-red);

				&::before,
				&::after {
					background-color: var(--white);
				}
			}
		}
	}

	&__bg {
		&::before {
			content: '';
			position: absolute;
			inset: 0;

			background: linear-gradient(
				180deg,
				rgba(5, 21, 45, 0.19) 0%,
				#05152d 100%
			);
		}
	}

	&__icon {
		transition: 0.3s ease;

		&::before,
		&::after {
			content: '';
			position: absolute;
			background-color: var(--midnight);
			transition: 0.3s ease;
		}

		&::before {
			width: 18px;
			height: 3px;
		}

		&::after {
			height: 18px;
			width: 3px;
		}
	}

	&__name {
		font-family: var(--font-sec);
		font-weight: 400;
		font-size: ac(40px, 30px);
		line-height: ac(40px, 34px);

		max-height: calc(ac(40px, 34px) * 4);
		@mixin max-line-length 4;
		padding-bottom: 2px !important;
	}

	&__text {
		font-size: 14px;
		line-height: 22px;
		max-height: calc(22px * 3);
		@mixin max-line-length 3;
	}

	@mixin md {
		&__nav {
			margin-left: ac(635px, 270px);
		}

		&__col {
			max-width: ac(500px, 350px);
		}

		&__slider {
			max-width: ac(394px, 300px);
		}
	}
}
