.detail-section {
  padding-top: ac(64px, 40px);
  padding-bottom: ac(99px, 40px);

  &__wrap {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 61.03%;
  }

  &__text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h1 {
      font-weight: 400;
      font-size: ac(90px, 30px, 320);
      line-height: ac(90px, 38px, 320);
      color: var(--denim) !important;

      &:not(:last-child) {
        margin-bottom: ac(24px, 20px);
      }
    }

    h2 {
      font-size: ac(50px, 34px);
      line-height: ac(50px, 40px);
      color: var(--denim) !important;

      &:not(:last-child) {
        margin-bottom: ac(24px, 20px);
      }
    }

    h4, p:first-child {
      font-size: ac(30px, 24px);
      line-height: 136%;
      font-weight: 400;
      font-family: var(--font-sec);
      color: var(--denim);

      &:not(:last-child) {
        margin-bottom: ac(24px, 20px);
      }
    }

    h3 {
      padding-left: ac(12px, 8px);
      padding-top: 4px;
      padding-bottom: 4px;
      padding-right: ac(12px, 8px);
      background: var(--electric-red);
      font-size: ac(30px, 24px);
      line-height: 136%;
      font-weight: 700;
      font-family: var(--font-sec);
      color: var(--white);

      &:not(:last-child) {
        margin-bottom: ac(28px, 24px);
      }
    }

    ul, ol {
      &:not(:last-child) {
        margin-bottom: ac(40px, 20px);
      }
    }

    p {
      &:not(:last-child) {
        margin-bottom: ac(40px, 20px);
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(48px, 30px);
    }
  }

  &__content-bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;

  }

  &__share {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }

  &__share-text {
    font-size: ac(20px, 18px);
    font-family: var(--font-main);
    line-height: 156%;
    font-weight: 500;
    color: var(--denim);
  }

  &__share-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  &__aside {
    width: 31.72%;
  }

  &__aside-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__aside-text {
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }
  }

  &__aside-help {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px ac(32px, 20px);
    border: 1px solid var(--electric-red);

    h3 {
      color: var(--electric-red);
      &:not(:last-child) {
        margin-bottom: ac(20px, 18px);
      }
    }
    
    &:not(:last-child) {
      &:not(:last-child) {
        margin-bottom: ac(40px, 24px);
      }
    }
  }

  &__aside-help-title {
    span {
      color: var(--midnight);
      transition: color .3s ease;
      font-size: ac(24px, 20px);
      line-height: 1.2;
      /*@mixin max-line-length-one;*/
      font-family: var(--font-sec);
      font-weight: 700;
    }

    &:not(:last-child) {
      margin-bottom: ac(16px, 14px);
    }
  }

  &__aside-help-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    span {
      color: var(--midnight);
      transition: color .3s ease;
      max-width: calc(100% - (24px + 12px));
      /*@mixin max-line-length-one;*/
      font-family: var(--font-sec);
      white-space: nowrap;
      font-weight: 400;
      text-decoration: underline;
    }

    i {
      color: var(--electric-red);
      font-size: 24px;
    }

    &:hover {
      span {
        color: var(--electric-red);
      }
    }
    
    &:not(:last-child) {
      margin-bottom: ac(16px, 14px);
    }
  }

  &__aside-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    h3 {
      &.name {
        font-weight: 700;
        line-height: 136%;
        color: var(--denim);
      }

      &:not(:last-child) {
        margin-bottom: ac(32px, 20px);
      }
    }

    .btn {
      width: 100%;
      margin-top: 0 !important;
    }

    .popup-contact__copy {
      padding-top: 16px;
      width: 100%;
      text-align: center;
    }

  }

  &__aside-form-grid {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    grid-column-gap: ac(20px, 16px);
    grid-row-gap: 20px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 768px) {
    .detail-section {
      &__wrap {
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        grid-row-gap: 30px;
      }

      &__content {
        width: 100%;
      }

      &__aside {
        width: 100%;
        max-width: 420px;
        position: relative !important;
        top: auto !important;

        .following-sidebar-buffer {
          display: none !important;
        }
      }
    }
  }
}

.jobs.job-detail-page {
  padding-bottom: 10px;
  overflow: hidden !important;
}