.process {
	margin-top: ac(130px, 70px);

	&__wrapper {
		padding: ac(130px, 70px) 0 ac(80px, 70px);
	}

	&__img {
		bottom: ac(-785px, -50px, 320);
		left: 50%;
		transform: translateX(calc(-50% - ac(350px, 0px, 320)));
		width: ac(1351px, 658px, 320);
		height: ac(1714px, 889px, 320);

		img {
			object-position: top;
		}
	}

	&__decor {
		bottom: ac(-299px, -99px);
		left: 50%;
		transform: translateX(calc(-50% - ac(28px, 231px, 320)));
		width: ac(1575px, 803px, 320);
		height: calc(100% - ac(60px, 277px, 320));
		min-height: 535px;
		mix-blend-mode: soft-light;
		filter: contrast(1.2);
	}

	@mixin lg {
		&__title {
			max-width: ac(394px, 240px, 1024);
		}
	}
}
