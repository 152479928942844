input,
textarea {
	padding: 12px 19px;
	width: 100%;
	border: 1px solid var(--electric-red);
	background-color: var(--white);
	border-radius: 10px;
	color: var(--midnight);
	@mixin transparent-bg-input var(--midnight);

	&,
	&::placeholder {
		font-size: 18px;
		line-height: 22px;
		font-weight: 300;
		transition: 0.3s ease;
	}

	&::placeholder {
	}
}

textarea {
	height: 92px;
	resize: none;
	scrollbar-width: thin;
	scrollbar-color: var(--midnight) rgba(30, 30, 30, 0.2);
	overflow-x: hidden;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		background: rgba(30, 30, 30, 0.2);
		border-radius: 0;
	}
	&::-webkit-scrollbar-thumb {
		background: var(--midnight);
		border-radius: 0;
	}
}

.input-box {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	p {
		span {
			color: v;
		}
	}

	&__upload-btn {
		flex-shrink: 0;

		margin-right: 12px;
		padding: 12px 16px;

		font-size: 18px;
		line-height: 22px;
		font-weight: 700;
		font-family: var(--font-sec);

		border: 1px solid var(--denim);
		background-color: var(--white);
		border-radius: 10px;

		overflow: hidden;
		transition: 0.3s ease;

		&:hover {
			background-color: var(--denim);
			color: var(--white);
		}
	}

	&__upload-label {
		font-size: 14px;
		line-height: 16px;
		font-weight: 300;
		transition: 0.3s ease;

		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}
