.searches {
	margin-top: ac(94px, 34px);

	&__wrapper {
		padding-top: ac(100px, 30px);
	}

	&__accordion-box {
		margin-top: ac(74px, 20px);
		margin-left: auto;
		max-width: 1228px;

		gap: 14px;
	}

	&__col {
		z-index: 1;
		overflow: hidden;
		cursor: pointer;
		border: 1px solid rgba(36, 54, 91, 0.5);

		&:before {
			content: '';
			position: absolute;
			width: calc(100% - 32px);
			height: calc(100% - 32px);
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: var(--electric-red);
			z-index: 1;
		}

		&.is-active {
			cursor: default;
			border: 1px solid rgba(5, 21, 45, 0.5);

			.searches__img {
				img {
					filter: grayscale(0);
				}
				&::before,
				&::after {
					opacity: 1;
				}
			}

			.searches__mask {
				opacity: 0;
			}
		}
	}

	&__img {
		img,
		&::before,
		&::after {
			transition: 0.3s ease;
		}

		&::before,
		&::after {
			opacity: 0;
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		img {
			filter: grayscale(1);
		}

		&::before {
			background-image: linear-gradient(
				0deg,
				rgba(36, 54, 91, 0.5),
				rgba(36, 54, 91, 0.5)
			);
			z-index: 1;
		}

		&::after {
			background-color: var(--denim);
			mix-blend-mode: color;
		}
	}

	&__mask {
		transition: 0.3s ease;
		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		&::before {
			mix-blend-mode: color;
			background-color: var(--denim);
		}

		&::after {
			background: linear-gradient(
				180deg,
				rgba(36, 54, 91, 0) 0%,
				rgba(36, 54, 91, 0.3) 40.61%,
				#24365b 100%
			);
		}
	}

	&__head {
		padding: 12px 0px 20px;
		position: relative;
		display: flex;
		flex-direction: column;
		z-index: 1;
	}

	&__name {
		font-family: var(--font-sec);
		font-size: ac(30px, 18px, 320);
		line-height: ac(30px, 20px, 320);
		font-weight: 700;
	}

	&__icon {
		cursor: pointer;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: ac(48px, 42px);
		height: ac(48px, 42px);
		flex-shrink: 0;
		transition: 0.3s ease;

		&::before,
		&::after {
			content: '';
			position: absolute;
			background-color: var(--white);
			transition: 0.3s ease;
		}

		&::before {
			width: ac(48px, 32px);
			height: ac(13px, 9px);
		}

		&::after {
			width: ac(13px, 9px);
			height: ac(48px, 32px);
		}
	}

	&__inner-title {
		width: 100%;
		padding: ac(20px, 15px);
		background-color: var(--electric-red);
		color: var(--white);
		font-family: var(--font-sec);
		font-size: ac(40px, 30px);
		line-height: ac(40px, 34px);
		font-weight: 700;
	}

	&__scroll.scroll {
		margin-top: ac(33px, 20px);
		max-height: 300px;
	}

	&__list {
		padding-left: 12px;
		padding-right: 6px;
		gap: ac(24px, 10px) ac(25px, 10px);
		padding-bottom: 2px;
	}

	&__item {
		font-size: ac(20px, 16px);
		line-height: ac(22px, 18px);
		font-weight: 700;
		font-family: var(--font-sec);
		color: var(--white);

		&::before {
			content: '';
			width: ac(20px, 14px);
			height: ac(20px, 14px);
			background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%23E0195B' d='M10 17.16 0 2.84h20L10 17.16Z'/%3E%3C/svg%3E")
				center / contain no-repeat;
		}
	}

	&__descr {
		margin-top: 40px;
	}

	@mixin md {
		&__text {
			max-width: ac(661px, 300px);
		}
	}

	@mixin lg {
		&__col {
			flex: 0;

			min-width: ac(104px, 100px, 1024);
			position: relative;
			padding: 16px;
			height: ac(576px, 400px);
			background-color: var(--midnight);
			transition: 0.2s 0.2s linear, box-shadow 0.3s ease;
			&:hover {
				border-color: var(--electric-red);
				box-shadow: 0 0 5px var(--electric-red);
			}
			&.is-active {
				flex: 1;
				padding: 15px;

				.searches__content {
					visibility: visible;
					opacity: 1;
					transition: all 0.2s ease, opacity 0.2s 0.4s linear;
				}

				&::before {
					opacity: 0;
					transition: none;
				}
				.searches__head {
					max-height: 0;
					opacity: 0;
					visibility: 0;
					padding: 0;
					transform: translateX(-100%);
					transition: none;
				}
			}
		}

		&__col {
			&::before {
				transition: opacity 0.3s 0.3s ease;
			}
		}

		&__head {
			height: 100%;
			transition: opacity 0.3s 0.3s ease;
		}

		&__content {
			opacity: 0;
			max-height: 0;
		}

		&__name {
			writing-mode: vertical-rl;
			transform: rotate(180deg);
		}
	}

	@mixin max-lg {
		&__scroll.scroll {
			margin-top: 0;
		}

		&__accordion-box {
			display: grid;
		}

		&__col {
			width: 100%;

			&::before {
				width: calc(100% - 16px);
				height: calc(100% - 16px);
				transition: 0.3s 0.3s ease;
			}

			&.is-active {
				&::before {
					opacity: 0;
					transition: none;
				}
				.searches__content {
					max-height: 1000px;
					padding: 0px 0px 15px;
					opacity: 1;
					transition: all 0.2s ease, opacity 0.2s 0.3s ease;
				}

				.searches__icon {
					&::after {
						height: 0;
					}
				}
			}
		}

		&__content {
			max-height: 0;
			opacity: 0;
			transition: all 0.2s 0.2s ease, opacity 0.2s ease;
		}

		&__head {
			padding: 10px 15px;
			flex-direction: row;
			transition: 0.3s ease;
			background-color: var(--forest);
		}

		&__name {
			order: -1;
		}

		&__line {
			width: 100%;
			height: 1px;
		}

		&__icon {
		}

		&__inner-title {
			display: none;
		}
	}

	@mixin max-md {
		&__title-box {
			flex-direction: column;
		}

		&__logo-box {
			margin: 0 auto;
			width: 100%;
			max-width: 400px;
		}
	}

	@mixin max-sm {
		&__row {
			gap: 5px;
			flex-direction: column;

			span {
				text-align: center;
			}
		}
	}
}
