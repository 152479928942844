.values {
	margin-top: ac(56px, 30px);
	&__wrapper {
		padding-top: ac(60px, 30px);
		border-top: 4px solid var(--denim);
	}

	&__text {
		margin-top: ac(50px, 34px);
		width: 100%;
		max-width: 600px;
	}

	&__card {
		padding-top: 20px;
		border-top: 2px solid var(--electric-red);
	}
}
