.numbers {
	margin-top: ac(94px, 66px);

	&__wrapper {
		padding-top: ac(100px, 70px);
		border-top: 4px solid var(--denim);
	}

	&__card-box {
		margin-top: 30px;
		gap: 20px ac(30px, 17px);
	}

	&__name {
		margin-bottom: auto;
	}

	&__count-box {
		margin-top: ac(22px, 16px);
		display: inline-flex;
		font-family: var(--font-sec);
		font-size: ac(50px, 40px);
		line-height: 1em;
		font-weight: 700;
	}

	&__prefix {
		color: var(--electric-red);
	}

	&__text {
		margin-top: ac(50px, 34px);
		width: 100%;
		max-width: 600px;

		&:not(:last-child) {
			margin-bottom: ac(30px, 24px);
		}
	}
}
