.hero {
	&:not(.hero--who, .hero--client, .hero--areas, .hero--career) {
		padding: 120px 0 ac(154px, 80px);
		min-height: ac(600px, 550px);
		position: relative;
		display: flex;
		align-items: flex-end;
		height: 100vh;
		height: 100svh;

		background-color: var(--denim);
		overflow: hidden;

		.hero__img {
			max-height: 200vh;
			bottom: ac(-708px, -204px, 375);
			left: 50%;
			transform: translateX(calc(ac(276px, -59px, 375) - 50%));
			width: ac(1351px, 858px, 375);
			height: ac(1714px, 1089px, 375);

			img {
				object-position: top;
			}
		}

		.hero__decor {
			top: ac(128px, 99px);
			left: 50%;
			transform: translateX(calc(-50% - ac(150px, 331px, 375)));
			width: ac(1575px, 1003px, 375);
			height: calc(100% - ac(60px, 277px, 375));
			min-height: 535px;
			mix-blend-mode: soft-light;
			filter: contrast(1.2);
		}

		.hero__subtitle {
			margin-top: ac(32px, 20px);
		}
	}

	&--career,
	&--areas,
	&--client,
	&--who {
		margin-top: ac(211px, 120px);

		.hero__title {
			padding: 4px ac(20px, 10px);

			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: var(--electric-red);
				z-index: -1;
			}
		}
	}

	@mixin xxl {
		&:not(.hero--who, .hero--client, .hero--areas, .hero--career) {
			.hero__img {
				bottom: ac(-200px, -708px, 1440, 2560);
			}
		}
	}

	@mixin md {
		&:not(.hero--who, .hero--client, .hero--areas, .hero--career) {
			align-items: center;
		}
	}

	@media (max-height: 768px) {
		&:not(.hero--who, .hero--client, .hero--areas, .hero--career) {
			padding-bottom: 50px;

			.hero__img {
				bottom: auto;
				top: -60px;
			}
		}
	}

	@media (max-height: 1400px) and (min-width: 1920px) {
		&:not(.hero--who, .hero--client, .hero--areas, .hero--career) {
			.hero__img {
				bottom: auto;
				top: 0;
			}
		}
	}
}
