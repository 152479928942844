.reviews {
	margin-top: ac(56px, 30px);

	&__wrapper {
		padding-top: ac(60px, 30px);
	}

	&__img {
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				180deg,
				rgba(5, 21, 45, 0.19) 0%,
				#05152d 100%
			);
		}
	}

	&__quotes {
		width: 66px;
		height: 32px;
		background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='66' height='32' fill='none'%3E%3Cpath fill='%23E0195B' d='M6.756 23a6.672 6.672 0 0 1-3.296-.872C2.472 21.493 1.648 20.62.99 19.51.329 18.4 0 17.131 0 15.703c0-1.744.384-3.516 1.153-5.313.824-1.85 2.005-3.649 3.543-5.393C6.234 3.199 8.156 1.533 10.463 0l2.142 2.141c-1.538 1.692-2.774 3.49-3.707 5.393-.88 1.851-1.318 3.622-1.318 5.314 0 1.798.494 3.252 1.482 4.362a14.93 14.93 0 0 0 3.378 2.697c-.494 1.163-1.318 1.983-2.471 2.459-1.154.422-2.225.634-3.213.634Zm16.395 0a6.672 6.672 0 0 1-3.296-.872c-.989-.635-1.812-1.507-2.471-2.618-.66-1.11-.99-2.379-.99-3.807 0-1.744.385-3.516 1.154-5.313.824-1.85 2.005-3.649 3.543-5.393C22.629 3.199 24.55 1.533 26.858 0L29 2.141c-1.538 1.692-2.774 3.49-3.707 5.393-.88 1.851-1.319 3.622-1.319 5.314 0 1.798.495 3.252 1.483 4.362a14.93 14.93 0 0 0 3.378 2.697c-.494 1.163-1.318 1.983-2.471 2.459-1.154.422-2.225.634-3.213.634ZM59.477 8c1.114 0 2.175.303 3.182.91.955.662 1.75 1.573 2.387 2.731.636 1.159.954 2.483.954 3.973 0 1.82-.371 3.669-1.114 5.545-.795 1.93-1.935 3.806-3.42 5.627C59.98 28.662 58.125 30.4 55.898 32l-2.069-2.235c1.485-1.765 2.679-3.64 3.58-5.627.849-1.931 1.273-3.78 1.273-5.545 0-1.876-.477-3.393-1.432-4.552a14.668 14.668 0 0 0-3.261-2.813c.477-1.214 1.272-2.07 2.386-2.566C57.489 8.221 58.523 8 59.477 8Zm-15.83 0c1.114 0 2.175.303 3.183.91.954.662 1.75 1.573 2.386 2.731.636 1.159.954 2.483.954 3.973 0 1.82-.37 3.669-1.113 5.545-.796 1.93-1.936 3.806-3.42 5.627C44.15 28.662 42.294 30.4 40.067 32L38 29.765c1.485-1.765 2.678-3.64 3.58-5.627.848-1.931 1.272-3.78 1.272-5.545 0-1.876-.477-3.393-1.431-4.552a14.67 14.67 0 0 0-3.262-2.813c.477-1.214 1.273-2.07 2.386-2.566C41.66 8.221 42.694 8 43.648 8Z'/%3E%3C/svg%3E")
			center / contain no-repeat;
	}
}
