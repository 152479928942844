.founded {
	margin-top: ac(52px, 30px);

	&__wrapper {
		padding-top: ac(60px, 30px);
		border-top: 4px solid var(--denim);
	}

	&__row {
		position: relative;

		.btn {
			display: flex;
			justify-content: center;
			align-items: center;

			.svg-icon {
				display: flex;
				justify-content: center;
				align-items: center;
				--size: 20px;
				width: var(--size);
				height: var(--size);
				margin-left: 4px;
				padding: 1px;
			}

			svg {
				width: 100%;
				height: 100%;
				object-fit: contain;



				path {
					transition: .3s;
					fill: var(--electric-red:);
				}
			}

			&:hover {
				svg {
					path {
						fill: var(--white);
					}
				}
			}
		}
	}

	&__col {
		@media (min-width: 769px) {
			position: sticky;
			right: 0;
			top: 90px;
		}

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 4px;
			height: 100%;
			background-color: var(--denim);
			z-index: 1;
		}
	}
	&__text {
		flex: 1 0 ac(300px, 250px);

		p {
			&:not(:first-child) {
				margin-top: 10px;
			}
		}
	}

	&__position {
		padding: 11px 15px 11px 36px;
		background-color: var(--electric-red);
		font-size: ac(22px, 18px);
		line-height: 1em;
	}

	&__name,
	&__descr {
		margin-left: ac(36px, 15px);
	}

	&__name {
		margin-top: ac(18px, 12px);
	}

	&__descr {
		margin-top: ac(13px, 8px);
	}

	@mixin md {
		&__text-box {
			max-width: ac(818px, 400px);
		}
	}
}
