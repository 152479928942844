.ready {
	margin-top: ac(130px, 70px);

	&__wrapper {
		padding: ac(200px, 70px) 0 ac(163px, 70px);
	}

	&__subtitle {
		max-height: calc(ac(26px, 24px) * 5);
		@mixin max-line-length 5;
	}

	&__img {
		mix-blend-mode: luminosity;
	}

	&__mask {
		background: linear-gradient(
			0deg,
			rgba(36, 54, 91, 0.8),
			rgba(36, 54, 91, 0.8)
		);
	}

	&__decor {
		bottom: ac(-186px, -450px, 320);
		width: ac(1549px, 500px, 320);
		height: calc(100% + 28px);
		transform: translateX(calc(-50% + ac(14px, -50px)));
		mix-blend-mode: soft-light;
	}

	&__photo {
		width: ac(640px, 400px);
		height: ac(855px, 550px);
		transform: translateX(calc(-50% + ac(301px, 180px))) scaleX(-1);
		bottom: -109px;
	}

	&__btn {
		transition: 0.3s ease !important;
	}

	@media (min-width: 769px) {
		&__wrapper {
			max-width: ac(620px, 365px);
		}
	}

	@mixin max-md {
		&__wrapper {
			padding-bottom: 400px;
		}

		&__decor {
		}

		&__photo {
			width: ac(640px, 400px);
			height: ac(855px, 550px);
			transform: translateX(calc(-50% + 50px)) scaleX(-1);
			bottom: -109px;
		}
	}
}
