.footer {
	margin-top: ac(99px, 69px);

	&__wrapper {
		padding: ac(60px, 50px) 0;
		column-gap: ac(40px, 30px);
	}

	&__logo {
		path {
			transition: 0.3s ease;
		}
		&:hover {
			path {
				fill: var(--electric-red);
			}
		}
	}

	&__nav {
		gap: ac(50px, 13px) ac(50px, 16px);

		li {
			display: flex;
			justify-content: flex-start;
		}
	}

	&__top {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		gap: ac(50px, 13px) ac(50px, 16px);
		width: 100%;

		&:not(:last-child) {
			margin-bottom: ac(50px, 16px);
		}
	}

	&__link {
		font-family: var(--font-sec);
		font-size: 18px;
		font-weight: 700;
		line-height: 22px;
		transition: 0.3s ease;

		&:hover {
			color: var(--electric-red);
		}
	}

	&__soc {
		width: 24px;
		height: 24px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		transition: 0.3s ease;

		&--in {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%23E0195B' d='M4.98 3A2.49 2.49 0 0 1 2.5 5.5 2.49 2.49 0 0 1 .02 3C.02 1.62 1.13.5 2.5.5A2.49 2.49 0 0 1 4.98 3ZM5 7.5H0v16h5v-16Zm7.982 0H8.014v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V23.5H24V13.369c0-7.88-8.922-7.593-11.018-3.714V7.5Z'/%3E%3C/svg%3E");

			&:hover {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%2305152d' d='M4.98 3A2.49 2.49 0 0 1 2.5 5.5 2.49 2.49 0 0 1 .02 3C.02 1.62 1.13.5 2.5.5A2.49 2.49 0 0 1 4.98 3ZM5 7.5H0v16h5v-16Zm7.982 0H8.014v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0V23.5H24V13.369c0-7.88-8.922-7.593-11.018-3.714V7.5Z'/%3E%3C/svg%3E");
			}
		}
	}

	&__link {
		line-height: 24px;
	}

	&__tel {
		letter-spacing: -0.02em;
	}

	p.footer__tel {
		margin: ac(2px, 0px) 0 0 0;
		max-width: ac(606px, 560px);

		@media (min-width: 1023px) {
			margin-left: auto;
		}
	}
	a.footer__tel {
		transition: 0.3s ease;

		&:hover {
			color: var(--electric-red);
		}
	}

	&__copy-box {
		margin-top: ac(-4px, 20px);
		gap: 20px;
	}

	&__copy {
		font-size: 14px;
		line-height: 17px;
		font-weight: 300;
		color: var(--denim);
	}

	@mixin md {
		&__wrapper {
			row-gap: ac(40px, 20px);
		}
	}

	@mixin max-lg {
		&__soc-box {
			width: 100%;
		}
	}

	@mixin max-md {
		p.footer__tel {
			margin-top: 14px;
		}
		a.footer__tel {
			margin-top: 8px;
		}
		&__soc-box {
			margin-top: 20px;
			margin-bottom: 11px;
		}

		&__nav {
			margin-top: 20px;
			width: 100%;
			flex-wrap: wrap;

			li {
				flex: 1 0 150px;
			}
		}
	}
}
