.banner {
	margin-top: ac(130px, 70px);

	&:not(.banner--career) {
		.banner__title {
			&::before {
				content: '';
				position: absolute;
				top: ac(-88px, -40px);
				left: 2px;
				width: ac(95px, 60px);
				height: ac(46px, 30px);
				background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='95' height='46' fill='none'%3E%3Cpath fill='%23E0195B' d='M9.551 33c-1.63 0-3.184-.417-4.659-1.252-1.398-.91-2.562-2.162-3.494-3.755C.466 26.4 0 24.58 0 22.531c0-2.503.544-5.045 1.63-7.624 1.165-2.655 2.835-5.235 5.01-7.738C8.812 4.589 11.53 2.2 14.792 0l3.028 3.072C15.647 5.5 13.9 8.08 12.579 10.81c-1.242 2.655-1.863 5.197-1.863 7.624 0 2.58.699 4.666 2.097 6.26a21.167 21.167 0 0 0 4.775 3.868c-.699 1.669-1.864 2.845-3.494 3.528-1.63.607-3.145.91-4.543.91Zm23.18 0c-1.632 0-3.185-.417-4.66-1.252-1.398-.91-2.563-2.162-3.494-3.755-.932-1.593-1.398-3.414-1.398-5.462 0-2.503.544-5.045 1.63-7.624 1.165-2.655 2.835-5.235 5.01-7.738C31.991 4.589 34.71 2.2 37.971 0L41 3.072C38.826 5.5 37.079 8.08 35.758 10.81c-1.242 2.655-1.863 5.197-1.863 7.624 0 2.58.699 4.666 2.096 6.26a21.169 21.169 0 0 0 4.776 3.868c-.699 1.669-1.864 2.845-3.494 3.528-1.631.607-3.145.91-4.543.91ZM85.449 12c1.63 0 3.184.43 4.659 1.29 1.398.938 2.563 2.227 3.494 3.869C94.534 18.8 95 20.676 95 22.786c0 2.58-.544 5.198-1.63 7.855-1.166 2.736-2.835 5.394-5.01 7.973-2.173 2.657-4.891 5.12-8.153 7.386l-3.028-3.166c2.174-2.5 3.921-5.158 5.242-7.972 1.242-2.736 1.863-5.354 1.863-7.855 0-2.658-.699-4.807-2.097-6.448a21.301 21.301 0 0 0-4.775-3.987c.699-1.72 1.864-2.93 3.494-3.634 1.63-.625 3.145-.938 4.543-.938Zm-23.18 0c1.632 0 3.185.43 4.66 1.29 1.398.938 2.563 2.227 3.494 3.869.932 1.641 1.398 3.517 1.398 5.627 0 2.58-.543 5.198-1.63 7.855-1.165 2.736-2.835 5.394-5.01 7.973-2.173 2.657-4.891 5.12-8.153 7.386L54 42.834c2.174-2.5 3.921-5.158 5.242-7.972 1.242-2.736 1.863-5.354 1.863-7.855 0-2.658-.699-4.807-2.096-6.448a21.303 21.303 0 0 0-4.776-3.987c.699-1.72 1.864-2.93 3.494-3.634 1.631-.625 3.145-.938 4.543-.938Z'/%3E%3C/svg%3E")
					center / contain no-repeat;
			}
		}
	}

	&--cases {
		margin-top: ac(-117px, -60px);
		z-index: 1;

		.banner__wrapper {
			padding: ac(602px, 300px) 0 ac(98px, 60px);
		}

		.banner__decor {
			bottom: ac(-26px, -200px, 320);
		}
	}
	&--career {
		.banner__decor {
			height: calc(100% - 55px);
		}

		.banner__wrapper {
			padding: ac(337px, 90px) 0 ac(98px, 90px);
		}
	}

	&__wrapper {
		padding: ac(406px, 90px) 0 ac(224px, 100px);
	}

	&__mask {
		background: linear-gradient(
			225.52deg,
			rgba(5, 21, 45, 0) 30.6%,
			#05152d 89.95%
		);
	}

	&__decor {
		bottom: ac(-26px, -10px, 320);
		width: ac(1575px, 500px, 320);
		height: calc(100% - 60px);
		transform: translateX(calc(-50% + ac(-15px, -90px)));
		mix-blend-mode: darken;
	}
	&__btn {
		transition: 0.3s ease !important;
	}

	@mixin md {
		&__wrapper {
			max-width: ac(606px, 350px);
		}

		&--career {
			.banner__wrapper {
				max-width: ac(501px, 350px);
			}
		}
	}
}
