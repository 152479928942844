.clients {
	margin-top: ac(56px, 40px);

	&__wrapper {
		padding-top: ac(60px, 76px);
		border-top: 4px solid var(--denim);
	}

	&__title {
		margin-bottom: ac(60px, 44px);
	}

	&__slider-box {
		user-select: none;

		&:not(:last-child) {
			margin-bottom: 50px;
		}

		&:nth-child(1) {
			.clients__logo {
				height: ac(87px, 79px);
				padding: ac(13px, 16px) ac(12px, 0px);
			}
		}
		&:nth-child(2) {
			.clients__logo {
				height: ac(75px, 79px);
				padding: ac(15px, 8px) ac(12px, 0px);
			}
		}
		&:nth-child(3) {
			.clients__logo {
				height: ac(81px, 79px);
				padding: ac(11px, 16px) ac(20px, 0px);
			}
		}
	}
	&__slider {
		overflow: hidden;
	}

	&__head {
		margin-bottom: 19px;
	}

	&__name {
		font-family: var(--font-sec);
		font-size: 22px;
		line-height: 22px;
		font-weight: 700;
	}

	&__logo {
		border-bottom: 2px solid var(--midnight);
	}

	@mixin md {
		&__col {
			max-width: ac(606px, 350px);
			margin-right: ac(117px, 20px);
		}

		&__globe-box {
			max-width: ac(517px, 350px);
			height: 517px;
		}
	}

	@mixin max-md {
		&__globe-box {
			margin-top: 40px;
		}
	}
}
